import TextField from '@engined/client/components/forms/fields/TextField.js';
import { LocaleContextValue, useLocale } from '@engined/client/contexts/LocaleContext.js';
import {
  AUTH_LOGIN_PASSWORD,
  AUTH_LOGIN_PASSWORD_IS_REQUIRED,
  AUTH_LOGIN_USERNAME,
  AUTH_LOGIN_USERNAME_IS_REQUIRED,
} from '@asaprint/asap/locales/client.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import React from 'react';

export interface FormValues {
  username: string;
  password: string;
}

export const initialValues: FormValues = {
  username: '',
  password: '',
};

export function validate(values: FormValues, t: LocaleContextValue['t']) {
  const errors: FormErrors<FormValues> = {};
  if (!values.username) {
    errors.username = t(AUTH_LOGIN_USERNAME_IS_REQUIRED);
  }

  if (!values.password) {
    errors.password = t(AUTH_LOGIN_PASSWORD_IS_REQUIRED);
  }

  return errors;
}

interface Props {
  className?: string;
  variant?: 'standard' | 'filled' | 'outlined';
}

const LoginForm: React.FunctionComponent<Props> = ({ className, variant = 'standard' }) => {
  const { t } = useLocale();

  return (
    <div className={className}>
      <TextField
        variant={variant}
        required
        fullWidth
        label={t(AUTH_LOGIN_USERNAME)}
        name="username"
        autoComplete="username"
        autoFocus
        margin="dense"
      />
      <TextField
        variant={variant}
        required
        fullWidth
        name="password"
        label={t(AUTH_LOGIN_PASSWORD)}
        type="password"
        id="password"
        autoComplete="current-password"
        margin="dense"
      />
    </div>
  );
};

LoginForm.displayName = 'LoginForm';

export default React.memo<Props>(LoginForm);
