
            import createOneQuery from '@engined/core/build/graphql-plugin-lib';
            import _asaprint_asap_fragments from '@asaprint/asap/fragments.graphql';
            const doc = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Login"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"username"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},"directives":[]},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},"directives":[]}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"login"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"usernameOrEmail"},"value":{"kind":"Variable","name":{"kind":"Name","value":"username"}}},{"kind":"Argument","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RequestUser"},"directives":[]}]}}]}}],"loc":{"start":0,"end":205}};
            doc.loc.source = {"body":"\n          #import '@asaprint/asap/fragments.graphql'\n\nmutation Login($username: String!, $password: String!) {\n  login(usernameOrEmail: $username, password: $password) {\n    ...RequestUser\n  }\n}\n\n        ","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
          

    const names = new Set<string>();
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          const name = def.name.value
          if (names.has(name)) {
            return false;
          } else {
            names.add(name);
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(_asaprint_asap_fragments.definitions));


              const oneQuery = createOneQuery(doc);
              export default doc;
            
                  export const Login = oneQuery(doc, "Login");
                
