import { useMutation } from '@apollo/client/index.js';
import LoginForm, {
  FormValues,
  initialValues,
  validate as formValidate,
} from '@asaprint/asap/components/forms/LoginForm.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import {
  AUTH_LOGIN_DESCRIPTION,
  AUTH_LOGIN_FORGOTTEN_PASSWORD,
  AUTH_LOGIN_SIGN_IN,
} from '@asaprint/asap/locales/client.js';
import { DASHBOARD_ROUTE, AUTH_FORGOTTEN_PASSWORD_ROUTE } from '@asaprint/asap/routes.js';
import { Login } from '@asaprint/asap/routes/auth/login.graphql';
import { LoginMutation, LoginMutationVariables } from '@asaprint/asap/schema.client.types.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { LoaderFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Box, Button, LinearProgress, Link } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Link as RouterLink, redirect, useNavigate, useSearchParams } from 'react-router-dom';

const logger = getLogger('@intranet/routes/auth/login');

interface Props {}

const LoginPage: React.FunctionComponent<Props> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loginExecute] = useMutation<LoginMutation, LoginMutationVariables>(Login, {
    refetchQueries: ['App_Load'],
  });
  const { login } = useAuth();
  const { t } = useLocale();
  const [searchParams] = useSearchParams();
  const next = searchParams.get('next');

  const onSubmit: OnSubmit<FormValues> = async (values) => {
    try {
      const response = await loginExecute({ variables: { username: values.username, password: values.password } });
      login(response.data.login);
      navigate(next ?? DASHBOARD_ROUTE, {
        replace: true,
      });
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  return (
    <>
      <p>{t(AUTH_LOGIN_DESCRIPTION)}</p>
      <Form defaultValues={initialValues} validate={formValidate} onSubmit={onSubmit}>
        {({ formState: { isSubmitting } }) => (
          <Box sx={{ mt: 1 }}>
            <LoginForm />
            <Box sx={{ mt: 3, mb: 2 }}>
              {isSubmitting && <LinearProgress sx={{ my: 1 }} />}
              <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
                {t(AUTH_LOGIN_SIGN_IN)}
              </Button>
            </Box>
            <Box sx={{ mt: 1, textAlign: 'center' }}>
              <Link variant="body2" component={RouterLink} to={AUTH_FORGOTTEN_PASSWORD_ROUTE}>
                {t(AUTH_LOGIN_FORGOTTEN_PASSWORD)}
              </Link>
            </Box>
          </Box>
        )}
      </Form>
    </>
  );
};

LoginPage.displayName = 'LoginPage';

export default React.memo<Props>(LoginPage);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  if (ENV.SERVER) {
    const next = req.query.next as string;
    if (req.user) {
      return redirect(next ?? DASHBOARD_ROUTE);
    }
  }
  return null;
};
